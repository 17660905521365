@import '~ethos-design-system/src/components/Media/Media.scss';

.pill {
  align-items: center;
  align-self: center;
  background-color: var(--primary-cypress-25);
  border-radius: 24px;
  color: var(--theme-fg-default);
  display: flex;
  font-family: var(--Theinhardt-font-stack);
  font-size: var(--font-size-1);
  font-weight: 500;
  justify-content: center;
  letter-spacing: 0.08em;
  line-height: var(--line-heights-12);
  margin: var(--Space-8) 0;
  padding: var(--Space-6) var(--Space-10) var(--Space-4);
  text-transform: uppercase;

  @include for-laptop-and-up {
    align-self: flex-start;
    margin-top: var(--Space-32);
  }
}
