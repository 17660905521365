@import '~ethos-design-system/src/components/Media/Media.scss';

@mixin for-phone-and-laptop-mid {
  @media (max-width: $laptop-range-mid) {
    @content;
  }
}

@mixin for-tablet-and-laptop-mid {
  @media (min-width: $tablet-range-start) and (max-width: $laptop-range-mid) {
    @content;
  }
}

.bgContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  display: none;

  @include for-laptop-and-up {
    display: block;
  }
}

.bgImage {
  position: absolute;
  right: 200px;
  bottom: 0;
  z-index: 0;

  @include for-tablet-and-laptop-mid {
    right: 100px;

    img {
      width: 460px;
      height: 510px;
    }
  }
}

.button {
  max-width: 500px;
  font-size: 20px !important;
  color: #272727;
  text-transform: uppercase !important;
}

.dailyPrices {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 400px;
  margin: 32px 0 0;
  padding: min(18vw, 72px) min(8vw, 32px) min(8vw, 24px);
  position: relative;
  row-gap: var(--Space-16);
  width: 330px;

  .backgroundImage {
    left: 0;
    position: absolute;
    top: 0;
    z-index: -1;
  }

  p {
    margin: 0;
  }

  @include for-laptop-and-up {
    bottom: var(--Space-48);
    height: 420px;
    padding-bottom: 8px;
    padding-top: 72px;
    position: absolute;
    right: calc(0px - 45dvw);
    width: 330px;
  }

  @include for-laptop-mid-and-up {
    right: calc(0px - 40dvw);
  }
}

.ellipse {
  background: linear-gradient(#c3d7d5, #f5f4ed);
  border-radius: 50%;
  bottom: -200px;
  display: none;
  height: 848px;
  position: absolute;
  right: calc(-256px - 45dvw);
  width: 848px;
  z-index: -2;

  &.nudgedUp {
    bottom: -150px;
  }

  @include for-laptop-and-up {
    display: block;
  }

  @include for-laptop-mid-and-up {
    right: calc(-256px - 40dvw);
  }
}

.header {
  color: #056257;
  font-family: Cambon;
  font-size: 80px;
  font-weight: 700;
  letter-spacing: -2.72px;
  line-height: 90px;
  margin-bottom: 32px;
  margin-bottom: 36px !important;
  margin-top: 48px;
  text-align: center;
  width: 100%;

  p {
    margin: 0;
  }

  @include for-laptop-and-up {
    text-align: left;
  }

  @include for-phone-and-laptop-mid {
    font-size: 44px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -0.88px;
    margin-top: 20px;
    margin-bottom: 24px;
  }
}

.insurerComparison {
  align-items: center;
  background-color: var(--White);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  margin: 40px 0 0;
  max-width: 500px;
  padding: var(--Space-24);
  width: 100%;

  .leadingText {
    margin: 0 0 var(--Space-24);
  }

  .partnerIcons {
    max-width: 100%;
  }

  .subHeading {
    margin: 0 0 var(--Space-32);
  }

  @include for-laptop-and-up {
    bottom: 140px;
    height: 320px;
    position: absolute;
    right: calc(0px - 46dvw);
    width: clamp(340px, 30dvw, 400px);
  }

  @include for-laptop-mid-and-up {
    right: calc(0px - 41dvw);
  }
}

.leadingText {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
}

.lines {
  align-items: center;
  color: var(--theme-accent-default);
  display: flex;
  flex-direction: column;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: var(--Space-32);
  width: 100%;

  hr {
    background-color: var(--primary-cypress-50);
    height: 1px;
    width: 100%;
  }

  p {
    margin: 0;
  }

  @include for-laptop-and-up {
    align-items: flex-start;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: var(--Space-40);
  }
}

.logo {
  margin-bottom: 20px;
  img {
    width: 80px;
  }

  @include for-laptop-and-up {
    display: none;
  }
}

.logoWithPartner {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  position: relative;

  @include for-laptop-and-up {
    display: none;
  }

  .mobileLogo {
    display: flex;
    align-items: center;
    img {
      max-width: 104px;
      height: auto;
    }
  }
}
.subheader {
  color: var(--BrandCypress);

  font-family: var(--Theinhardt-font-stack);
  font-size: var(--font-size-2);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1.12px;
  line-height: var(--line-heights-12);
  margin: 0;
  text-align: center;
  text-transform: uppercase;

  @include for-tablet-and-up {
    text-align: left;
    font-size: var(--font-size-3);
  }
}

.subHeading {
  line-height: var(--line-heights-10);
  text-align: center;
}

.trailingText {
  color: #7e7e7e;
  font-size: 10px;
  line-height: 14px;
  margin-top: var(--Space-32);
}

.trustImages {
  align-items: center;
  display: flex;
  gap: 42px;
  justify-content: center;
  padding: 48px 0;
  width: calc(100% + 48px);

  &.solid {
    background-color: var(--neutrals-night-5);
    margin-top: 64px;
  }

  .trustImage {
    width: 155px;
    height: 94px;
    margin: 0;
    object-fit: contain;

    &:last-child {
      padding-bottom: 1px;
    }
  }

  @include for-tablet-and-up {
    width: calc(100% + 140px);
  }

  @include for-laptop-and-up {
    width: 100%;

    &.solid {
      background-color: transparent;
      margin-top: 0;
    }
  }
}

.wrapper {
  background: linear-gradient(
    135deg,
    #e6f5ec -5.9%,
    rgba(235, 240, 249, 0.98) 138.44%
  );

  display: flex;
  justify-content: center;
  overflow: hidden;

  .container {
    display: flex;
    max-width: 1440px;
    min-height: 400px;
    padding: 32px 120px 0;
    position: relative;
    width: 100%;
    z-index: 1;

    @include for-tablet-and-up {
      min-height: 600px;
    }

    @include for-phone-and-laptop-mid {
      padding: 32px 24px 0;
    }

    @include for-tablet-and-laptop-mid {
      padding: 32px 72px 0;
    }
  }

  .mainContent {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    z-index: 1;

    @include for-laptop-and-up {
      align-items: flex-start;
      max-width: 500px;
      width: unset;
    }
  }
}
