.container {
  text-align: center;
  width: 100%;
  color: rgb(102, 102, 102);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  margin-bottom: 80px;
}

.innerContainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: var(--BrandMoss--translucent);
  box-shadow: 0 4px 12px #10101033;
  border-radius: 24px;
  overflow-wrap: break-word;
  padding: 32px 16px;
  position: relative;
  max-width: 580px;
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;

  img {
    max-height: 66px;
    height: 15vw;
  }
}

.button {
  padding: 15px 30px;
  font-size: 16px;
  background-color: #054742;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 32px;
}

.status {
  margin-top: 20px;
  font-size: 16px;
}
