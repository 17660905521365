@import '~ethos-design-system/src/components/Media/Media.scss';
// TODO assess how much of this is needed to keep
@import '../styles/background-color.module.scss';

@mixin for-phone-and-laptop-mid {
  @media (max-width: $laptop-range-mid) {
    @content;
  }
}

@mixin for-tablet-and-laptop-mid {
  @media (min-width: $tablet-range-start) and (max-width: $laptop-range-mid) {
    @content;
  }
}

.hidden {
  display: none;
}

.singleCTA {
  padding-bottom: 0;
  @media (max-width: 767px) {
    overflow: hidden;
  }
  @media (min-width: 768px) {
    height: 500px;
  }
  @media (min-width: 1440px) {
    height: 729px;
  }
}

.laptopMidAndUpOnly {
  display: none;
  @include for-laptop-mid-and-up {
    display: block;
  }
}

.trustImage {
  height: 100%;
  max-height: 54px;
  max-width: 87px;
  margin: 0 auto;
  object-fit: contain;

  @include for-phone-only {
    max-height: 42px;
    max-width: 71px;
  }
  @include for-tablet-and-up {
    min-width: 72px;
  }
}

.trustImageV2 {
  height: 100%;
  margin: 0 auto;
  object-fit: contain;
  width: 142px;
  height: 85px;
}

.backgroundImageSingleCTA {
  @include for-phone-only {
    height: 625px;
    overflow: hidden;
  }
  @include for-tablet-and-up {
    height: 500px;
  }
  @media (min-width: 1440px) {
    height: 729px;
  }
}

.numberHeaderContainerWrapper {
  display: grid;
  margin: 34px auto 0;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 8px;
  max-width: 451px;

  @include for-laptop-mid-and-up {
    margin: 42px 0 0;
  }
}
.content {
  text-align: center;
  margin: 0 auto;
  max-width: 135px;
  color: #000;
}

.last {
  max-width: 100%;
}

.cardWrapper {
  background: rgba(255, 255, 255, 0.5);
  padding: 10px;
  height: 100%;
  display: flex;
  align-items: center;

  &:last-child {
    grid-column: span 2;
  }

  &:first-child {
    border-radius: 16px 2px 2px 2px;
  }

  &:nth-child(2) {
    border-radius: 2px 16px 2px 2px;
  }

  &:nth-child(3) {
    border-radius: 2px 2px 16px 16px;
  }
}

.singleCtaButton {
  button {
    border-radius: 4px;
  }
  &.numberHeaderCtaButton {
    button {
      border-radius: 8px;
    }
  }
}

.singleCTAForeground {
  min-height: 514px;
  &.short {
    min-height: 370px;
    @include for-laptop-and-up {
      min-height: 514px;
    }
  }

  &.numberHeaderCtaBackground {
    border-radius: 0px 0px 0px 48px;

    @include for-tablet-and-up {
      border-radius: 0px 0px 0px 200px;
    }
  }
}

.singleCtaPlaceholder {
  min-height: 839px;
  background: var(--BrandMoss);
  border-radius: 0 0 0 200px;
  position: absolute;
  width: 100%;
  z-index: -1;
  @include for-tablet-and-up {
    min-height: 514px;
  }
}

.singleCTAForegroundContainer {
  @include for-desktop-only {
    max-width: 1200px;
  }
  p {
    margin: 0;
  }
}

.foregroundImageSingleCTA {
  max-width: 350px;
  margin: 0 auto;
  @include for-laptop-and-up {
    max-width: 460px;
    max-height: auto;
    margin: auto;
  }

  &.treatment2 {
    @include for-desktop-only {
      max-width: 100%;
    }
  }

  &.numbersHeaderCta {
    @include for-laptop-mid-and-up {
      max-width: 378px;
    }
  }
}

.curvedOverlay {
  background-color: var(--BrandCypress);
  @media (min-width: 768px) {
    width: 435px;
    height: 421px;
    left: 0;
    padding-left: 30px;
    border-bottom-right-radius: 250px;
  }
  @media (min-width: 1024px) {
    height: 521px;
  }
  @media (min-width: 1440px) {
    width: 515px;
    left: 40px;
    padding-left: 0;
  }
}

.backgroundFiller {
  background-color: var(--BrandCypress);
  width: calc(50vw - 415px);
}

.contentContainer {
  text-align: center;
  @media (min-width: 768px) {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
    max-width: 1200px;
    height: 421px;
    text-align: left;
  }
  @media (min-width: 1024px) {
    height: 521px;
  }
}

.imageContainer {
  @media (min-width: 768px) {
    img {
      height: 421px;
    }
  }
  @media (min-width: 1024px) {
    img {
      height: 521px;
    }
  }
  @media (min-width: 1440px) {
    img {
      transform: translate(100px, 0);
    }
  }
}

.ctaContainer {
  @media (min-width: 768px) {
    max-width: 255px;
  }
}

$overlaySize: 250px;
$halfOverlaySize: 125px;
.whiteCurveOverlay {
  position: absolute;
  z-index: 1;
  width: $overlaySize;
  height: $overlaySize;
  bottom: -2px;
  left: 0;
  .inset {
    width: $overlaySize;
    height: $overlaySize;
    overflow: hidden;
    position: relative;
    &:before {
      content: '';
      bottom: 0;
      left: 0;
      box-shadow: -$halfOverlaySize $halfOverlaySize 0 0 var(--White);
      display: block;
      width: 200%;
      height: 200%;
      position: absolute;
      border-radius: 50%;
    }
  }
}

$smallOverlaySize: 50px;
$smallHalfOverlaySize: 25px;
.smallWhiteCurveOverlay {
  position: absolute;
  z-index: 1;
  width: $smallOverlaySize;
  height: $smallOverlaySize;
  bottom: -2px;
  left: 0;
  @include for-laptop-and-up {
    width: $overlaySize;
    height: $overlaySize;
  }
  .inset {
    width: $smallOverlaySize;
    height: $smallOverlaySize;
    @include for-laptop-and-up {
      width: $overlaySize;
      height: $overlaySize;
    }
    overflow: hidden;
    position: relative;
    &:before {
      content: '';
      bottom: 0;
      left: 0;
      box-shadow: -$smallHalfOverlaySize $smallHalfOverlaySize 0 0 var(--White);
      @include for-laptop-and-up {
        box-shadow: -$halfOverlaySize $halfOverlaySize 0 0 var(--White);
      }
      display: block;
      width: 200%;
      height: 200%;
      position: absolute;
      border-radius: 50%;
    }
  }
}

.boldHeader {
  font-family: 'Cambon';
  font-style: normal;
  font-weight: 700;
  font-size: 43.5px;
  line-height: 48px;
  letter-spacing: -0.0275em;

  @include for-tablet-and-up {
    font-size: 60px;
    line-height: 64px;
    letter-spacing: -0.034em;
  }

  p {
    margin: 0;
  }

  &.cypressText * {
    color: var(--BrandCypress);
  }
}

.wrapper {
  position: relative;
}

.spearmintBackground {
  background: #e6f5ec;
}

.finalExpense {
  position: relative;
  background: linear-gradient(45deg, #daefe3 0%, #c0d8d5 100%);
  display: flex;
  justify-content: center;
  overflow: hidden;

  @include for-phone-and-laptop-mid {
    display: block;

    br {
      display: none;
    }
  }

  .container {
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 1440px;
    padding: 0 120px;

    @include for-phone-and-laptop-mid {
      padding: 0 24px;
    }

    @include for-tablet-and-laptop-mid {
      padding: 0 72px;
    }
  }

  .mainContent {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include for-phone-and-laptop-mid {
    }
  }

  .header {
    color: #272727;
    font-family: Cambon;
    font-size: 80px;
    font-weight: 600;
    line-height: 90px;
    letter-spacing: -2.72px;
    margin-bottom: 32px;
    margin-top: 48px;

    p {
      margin: 0;
    }

    @include for-phone-and-laptop-mid {
      font-size: 48px;
      font-weight: 700;
      line-height: 64px;
      letter-spacing: -0.96px;
      margin-top: 20px;
      margin-bottom: 24px;
    }
  }

  .subText {
    max-width: 510px;
    color: #000;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -0.448px;
    margin-bottom: 64px;

    @include for-phone-and-laptop-mid {
      max-width: initial;
      margin-bottom: 32px;
      font-size: 24px;
      line-height: 34px;
      letter-spacing: -0.24px;
    }
  }

  .cta {
    width: 100%;
    max-width: 510px;
    margin-bottom: 40px;

    > button {
      border-radius: 8px;
      justify-content: center;
      align-items: baseline;
      color: #fff;
      font-size: 24px;
      font-weight: 500;
      letter-spacing: -0.24px;
      gap: 8px;
      padding: 24px 48px;
      box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
        0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    }

    @include for-phone-and-laptop-mid {
      margin-bottom: 24px;
      max-width: initial;
    }
  }

  .trustImages {
    max-width: 510px;
    display: flex;
    align-items: center;
    gap: 42px;
    margin-bottom: 76px;

    .trustImageContainer {
      height: 24px;
    }

    @include for-phone-and-laptop-mid {
      max-width: initial;

      .trustImageContainer {
        height: 20px;
        &:nth-child(n + 4) {
          display: none;
        }
      }

      justify-content: center;
      margin-bottom: 24px;
    }
  }

  .bgContainer {
    position: absolute;
    max-width: 1440px;
    width: 100%;
    height: 100%;

    @include for-phone-and-laptop-mid {
      position: static;
      height: auto;
      width: auto;
      max-width: initial;
    }
  }

  .bgImage {
    position: absolute;
    bottom: -125px;
    right: -150px;
    z-index: 0;

    @include for-phone-and-laptop-mid {
      position: static;
    }

    @include for-phone-only {
      margin-top: 30px;
      img {
        scale: 1.15;
      }
    }

    @include for-tablet-and-laptop-mid {
      position: relative;
      bottom: -40px;
      right: initial;
      width: 60%;
      margin: 0 auto;
    }
  }

  @media (min-width: 1024px) and (max-width: 1220px) {
    .container {
      padding: 0 80px;
    }

    .header {
      font-size: 60px;
      line-height: 80px;
    }

    .bgImage {
      width: 80%;
    }
  }

  .ctaPhoneContainer {
    display: flex;
    align-items: center;
    flex-direction: column;

    button {
      width: 100%;
      border-radius: 8px;
      justify-content: center;
      text-align: center;
      color: #fff;
      font-size: 24px;
      font-weight: 500;
      letter-spacing: -0.24px;
      padding: 32px 48px;
      line-height: 14px;
      box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
        0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    }

    @include for-laptop-mid-and-up {
      flex-direction: row;
      margin-bottom: 40px;
      button {
        width: max-content;
      }
    }
  }

  .phoneNumber {
    line-height: var(--Space-40);
    letter-spacing: 0em;
    color: #05594f;
    font-size: 28px;
    text-decoration: underline;
    text-underline-offset: 6px;
    font-weight: 500;
    text-decoration-thickness: 2px;
  }

  .phoneContainer {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;

    @include for-laptop-mid-and-up {
      text-align: left;
      align-items: flex-start;
      margin-left: 32px;
      margin-top: 0px;
    }
  }
}
