@import '~ethos-design-system/src/components/Media/Media.scss';
// TODO assess how much of this is needed to keep
@import '../styles/background-color.module.scss';

.willsTrustworthy {
  padding: 60px 60px 0 !important;

  @include for-phone-and-tablet {
    display: block;
    padding: 16px 16px 0 !important;
  }
}

.trustworthy {
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding: 20px 60px 0;

  @include for-phone-and-tablet {
    display: block;
    padding: 12px 0 0;
  }

  .container {
    background: linear-gradient(81deg, #056257 -1.36%, #fef0e5 139.47%);
    border-radius: 24px;
    margin-bottom: var(--Space-24);
    max-width: 1320px;
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: 1;

    &.brandVariant1 {
      background: linear-gradient(to top right, #045047, #077e70);
    }

    &.brandVariant2 {
      background: linear-gradient(to top right, #045047, #077e70);
    }

    &.brandVariant3 {
      background: linear-gradient(to top right, #ebfcf4, #e4f8f6);
    }

    @include for-phone-and-tablet {
      padding: 24px 24px 0;
      border-radius: 12px;

      &:not(.brandVariant1, .brandVariant2, .brandVariant3) {
        background: linear-gradient(21deg, #fef0e5 -72.1%, #056257 86.88%);
      }
    }
  }

  .mainContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 1;
    position: relative;
    padding-left: 80px;

    @include for-phone-and-tablet {
      padding-left: 0;
    }
  }

  .header {
    color: white;
    font-family: Cambon;
    font-size: 80px;
    font-weight: 700;
    line-height: 100%; /* 80px */
    letter-spacing: -1.496px;
    margin-bottom: 32px;
    margin-top: 50px;

    &.brandVariant3 {
      color: #05594f;
    }

    @include for-phone-and-tablet {
      font-size: 50px;
      font-weight: 700;
      line-height: 58px;
      letter-spacing: -1.457px;
      margin-top: 12px;
      margin-bottom: 24px;
    }
  }

  .fiveMinutesHeading {
    max-width: 600px;
    @include for-phone-and-tablet {
      max-width: 100%;
    }
    @media (max-width: 390px) {
      font-size: 44px;
    }
  }

  .willsHeading {
    max-width: 650px;
    @include for-phone-and-tablet {
      max-width: 100%;
    }
    @media (max-width: 390px) {
      font-size: 40px;
    }
  }

  .willsContainer {
    margin-bottom: 32px !important;

    @include for-phone-and-tablet {
      margin-bottom: 24px !important;
    }
  }

  .affordablePlans {
    display: flex;
    align-items: center;
    align-self: flex-start;
    gap: 12px;
    margin-bottom: 32px;

    @include for-phone-and-tablet {
      align-self: center;
      margin-bottom: 24px;
    }

    .planText {
      color: #fff;
      /* Display M Bold */
      font-family: NewTheinhardt;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 34px;
      letter-spacing: -0.24px;

      @include for-phone-and-tablet {
        /* Body L Bold */
        font-family: Theinhardt;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px; /* 144.444% */
      }
    }
  }

  .bentoContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    max-width: 450px;
    width: 100%;
    margin-bottom: 48px;

    .box {
      align-items: center;
      background-color: #e6f5ec;
      color: #000000;
      display: flex;
      font-size: 18px;
      justify-content: center;
      line-height: 26px;
      padding: 14px 0;
      text-align: center;

      &.brandVariant1 {
        background-color: #ebfcf4;
        color: #04463e;
      }

      &.brandVariant2 {
        background-color: #ebfcf4;
        color: #04463e;
      }

      &.brandVariant3 {
        background-color: #ffffff;
        color: #04463e;
      }

      &:first-child {
        border-radius: 16px 2px 2px 2px;
      }

      &:nth-child(2) {
        border-radius: 2px 16px 2px 2px;
      }

      &:last-child {
        border-radius: 2px 2px 16px 16px;
        grid-column: span 2;

        .boxContent {
          max-width: initial;
        }
      }

      .boxContent {
        max-width: 145px;
      }

      &:first-child {
        .willsBoxContent {
          max-width: 160px;
        }
      }
    }

    @include for-phone-and-tablet {
      margin-bottom: 32px;
      max-width: initial;

      .box {
        padding: 8px 0;
      }
    }
  }

  .willsCtaV2_2 {
    gap: 8px;

    button {
      height: 56px;
      text-wrap: nowrap;
    }

    width: 100%;
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;

    @include for-laptop-and-up {
      max-width: 450px;
      flex-direction: row;

      button {
        max-width: 246px;
      }
    }
  }

  .willsCta {
    width: 100%;
    max-width: 450px;
    margin-bottom: 48px;
    display: flex;
    align-self: center;

    > button {
      @include for-laptop-and-up {
        min-height: 56px;
      }
    }

    @include for-laptop-and-up {
      align-self: flex-start;
      margin-bottom: 32px;
    }
  }

  .cta {
    background-color: #f49640;
    border-radius: 8px;
    line-height: initial;
    margin-bottom: 40px;
    max-width: 450px;
    width: 100%;

    > button {
      font-size: 18px;
      font-weight: 700;
      line-height: initial;
      text-transform: uppercase;
      padding: 20px 48px;
      color: #272727;
    }

    &.brandVariant1 {
      background-color: #e7fd7f;

      > button {
        color: #04463e;
      }
    }

    &.brandVariant2 {
      background-color: #59f8b1;

      > button {
        color: #04463e;
      }
    }

    &.brandVariant3 {
      background-color: #05594f;

      > button {
        color: #ffffff;
      }
    }

    @include for-phone-and-tablet {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 64px;
      margin-bottom: 32px;
      max-width: initial;
      > button {
        font-size: 20px;
        font-weight: 700;
      }
    }
  }

  .trustImages {
    display: flex;
    gap: 24px;
    justify-content: space-between;
    max-width: fit-content;
    margin: 0 auto 47px;

    .trustImageContainer {
      height: unset;
      width: auto;
      max-width: 100%;
      img {
        height: 89px;
        width: auto;
      }
    }

    .willsTrustImageConainter {
      width: auto;
      height: unset;
      max-width: 100%;
      img {
        height: auto;
        width: 208px;

        @include for-phone-and-tablet {
          height: auto;
          width: 135px;
          max-width: 100%;
        }
      }
    }

    @include for-phone-and-tablet {
      max-width: initial;
      justify-content: space-around;
      margin-bottom: 24px;
    }

    @include for-laptop-and-up {
      margin: 0 0 40px;
      gap: 40px;
      .trustImageContainer > img {
        height: 104px;
      }
    }
  }

  .trustImageTitle {
    font-size: 18px;
    color: white;
    margin-top: 14px;
    line-height: 24.8px;
    text-align: center;
    @include for-phone-and-tablet {
      font-size: 14px;
      margin-top: 8px;
      line-height: 20px;
    }
  }

  .trustImageWrapper {
    width: 100%;
    max-width: 161px;
    @include for-laptop-and-up {
      max-width: 213px;
    }
  }

  .bgImage {
    position: absolute;
    bottom: 0;
    right: 5%;
    width: 43%;
    max-height: 250px !important;
    max-width: 400px !important;

    @include for-phone-and-tablet {
      margin: 0 auto;
      transform-origin: center top;
      position: static;
      max-height: 400px;
      width: 100%;
    }

    @include for-laptop-and-up {
      max-height: initial !important;
      max-width: initial !important;
    }
  }

  .tsBgImage {
    right: 0;
    width: 53%;

    @include for-phone-and-tablet {
      width: 100%;
    }
  }

  p {
    margin: 0;
  }
}
