@import '~ethos-design-system/src/components/Media/Media.scss';

.annualAdjustment {
  align-items: flex-end;
  color: var(--theme-fg-subtle-2x);
  display: flex;
  flex-direction: column;

  .perYear {
    font-size: var(--font-size-0);
    letter-spacing: 0.125px;
  }

  .adjustment {
    font-size: var(--font-size-3);
    font-weight: 500;
    letter-spacing: -1px;

    &::before {
      content: '$';
    }

    .cents {
      display: none;

      &::before {
        content: '.';
      }

      @include for-laptop-and-up {
        display: inline;
      }
    }
  }
}

.card {
  align-items: center;
  background-color: var(--theme-bg-surface);
  border-radius: 8px;
  box-shadow: 0 4px 30px 0 rgba(0 0 0 / 25%);
  display: flex;
  flex-grow: 2;
  justify-content: space-between;
  padding: var(--Space-16) var(--Space-24);
  position: relative;
  width: 100%;
  z-index: 3;

  &:not(:first-of-type) {
    background-color: var(--theme-bg-subtle);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: var(--Space-8) var(--Space-24);
  }

  &:nth-child(2) {
    padding: var(--Space-8) var(--Space-24) var(--Space-8) var(--Space-32);
    width: calc(100% - 32px);
    z-index: 2;
  }

  &:nth-child(3) {
    width: calc(100% - 64px);
    z-index: 1;

    .dailyPrice .price {
      font-size: var(--font-size-2);
    }
  }
}

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: var(--Space-8) 0 var(--Space-24);
  width: 100%;
}

.dailyPrice {
  align-items: flex-end;
  color: var(--theme-fg-subtle-2x);
  display: flex;
  flex-direction: column;

  &.featured {
    .price {
      color: var(--secondary-spearmint-100);
      font-size: var(--font-size-5);
    }
  }

  .perDay {
    font-size: var(--font-size-0);
    letter-spacing: 0.125px;
  }

  .price {
    font-size: var(--font-size-3);
    font-weight: 500;
    letter-spacing: -1px;

    &::before {
      content: '$';
    }
  }
}
